<template>
	<div class="container">
		<lightBox ref="lightBox" :actIndex=actIndex :images=images :animation="true"/>

		
		<div class="cols2_2-8">
			<div v-if="imgs">
				<img v-for="img in imgs" :src="$store.state.server + '/' + img.path" :alt="img.alt_text" class="img1"  :title="img.title"  @click="showLighBoxMulti">
			</div>
			
			
			<div> <!-- right -->
				<div class="hl1">
					<h3 class="cap" style="margin: 0rem 0">Garten und Teichpflege</h3>
				</div>

				<div class="cap f2 bor1" style="margin: 1.3rem 0">
					<div>Im Rahmen der Gartenpflege bieten wir:</div>
							<div >
								<ul>
									<li><span class="hgl1">Befreiung der Pflanzbeete von Unkraut</span></li>
									<li><span class="hgl1">Strauch- und Heckenschnitt</span></li>
									<li><span class="hgl1">Verpflanzen von Sträuchern und Stauden</span></li>
									<li><span class="hgl1">Mähkanten stechen</span></li>
									<li><span class="hgl1">Abschneiden der Stauden im Herbst</span></li>
									<li><span class="hgl1">Rosenschnitt</span></li>
									<li><span class="hgl1">Düngung und Schädlingsbekämpfung</span></li>
									<li><span class="hgl1">Teichreinigung</span></li>
									<li>Auf Wunsch erstellen wir nach einer Besichtigung <br><span class="hgl1">Anbote für die Jahrespflege</span></li>
								</ul>
							</div>
				</div>

			</div>
		</div>
		



	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	import axios from 'axios';
	import * as tools from '@/tools.js'; 
	import  lightBox from "@/components/LightBox.vue";

	export default {
		name: 'GG_Teichpflege',
		components: {
			// dialogComp,
			lightBox
		},
		mounted(){
			this.getImgs();

		}, // end mounted 
		data: 
		function(){
			return {
				images: [],
				actIndex: null,
				imgs: null,
			}
		}, // end data
		methods: {
			getImgs(){


				let self = this;
				let url = this.$store.state.server + '/api/getImagesIndex.php';



				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { slug: 'gartengestaltung-teichpflege' },
				})
				.then(function (resp) {

					self.imgs = resp.data.data[0];
					// self.pk = self.pkFilter = resp.data.data[1];

				});
			},
			showLighBoxMulti(e){

				this.images = []
				let coll = document.getElementsByClassName("container")[0].getElementsByTagName("img");

				let selSrc = e.target.src;

				let i = -1;
				    for (let item of coll) {
				    i++;

            if (item.src === selSrc) {
            	this.actIndex = i;
            }
            
				    this.images.push({src: item.src, height: item.naturalHeight, width: item.naturalWidth, title: item.title, alt: item.alt});
        }
			}


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">
	.container {
		height: 100%;
	}
	.img1 {
		margin-bottom: 2rem;
				cursor: pointer;
	}



</style>
